<template>
  <div class="edit-box">
    <!--  详情页的-->
    <template>
      <template>
        <h3 class="title">
          申请方信息
        </h3>
        <ul class="basic-information clear">
          <li class="flex-box">
            <span class="li-label">申请方：</span><span class="flex1">{{ detailList.recvCmpName }}</span>
          </li>
          <li class="flex-box">
            <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.recvCmpUnicode }}</span>
          </li>
        </ul>
      </template>
      <template>
        <h3 class="title">
          开立方信息
        </h3>
        <ul class="basic-information clear">
          <li class="flex-box">
            <span class="li-label">开立方：</span><span class="flex1">{{ detailList.openCmpName }}</span>
          </li>
          <li class="flex-box">
            <span class="li-label">统一社会信用代码：</span><span class="flex1">{{ detailList.openCmpUnicode }}</span>
          </li>
        </ul>
      </template>
    </template>
    <template>
      <h3 class="title">
        应收账款信息
      </h3>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">应收账款编号：</span>
          <span class="flex1" style="color:#00aaff;cursor: pointer;" @click="goPage(detailList)">{{ detailList.billNo }} 查看</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款余额：</span><span class="flex1">{{ detailList.payableAmt|formatMoney }}元</span>
        </li>
        <li class="flex-box">
          <span class="li-label">应收账款到期日：</span><span class="flex1">{{ detailList.acctDate }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">合同编号：</span><span class="flex1">{{ detailList.contractNos }}</span>
        </li>
        <li class="flex-box" style="width: 100%">
          <span class="li-label">选择发票：</span>
          <span class="flex1" style="padding-right: 30px">
            <el-table class="blue-theme" empty-text="暂无数据" :data="detailList.invoiceList">
              <el-table-column type="index" align="center" label="序号" width="50" />
              <el-table-column label="发票代码" align="center" prop="invoiceCode" />
              <el-table-column label="发票号码" align="center" prop="invoiceNo" />
              <el-table-column label="发票金额(含税/元)" align="center" prop="invoiceAmt">
                <template slot-scope="scope">
                  {{ scope.row.invoiceAmt|formatMoney }}
                </template>
              </el-table-column>
            </el-table>
          </span>
        </li>
      </ul>
    </template>
    <template>
      <h3 class="title">
        信链信息
      </h3>
      <ul class="basic-information clear">
        <li class="flex-box">
          <span class="li-label">信链编号：</span>
          <span v-if="detailList.voucherId" class="flex1" style="color:#00aaff;cursor: pointer;" @click="openFile(detailList.voucherId)">{{ detailList.echainNo }} 查看</span>
          <span v-else class="flex1">{{ detailList.echainNo }}</span>
        </li>
        <li class="flex-box">
          <span class="li-label">信链申请金额：</span><span class="flex1"><span
            class="red-color"
          >{{ detailList.echainApplyAmt|formatMoney }}</span> 元</span>
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
import { selectCmpApplayInfo } from '@/api/tradeFinancing.js'
export default {
  data() {
    return {
      detailList: {
        acctDate: '', // 应收账款到期日
        billNo: '', // 应收账款编号
        contractNos: '', // 合同编号
        buyCmpName: '', // 购买方名称（购买方企业名称）
        buyCmpUnicode: '', // 统一社会信用代码（购买方）
        invoiceList: [
          // {
          //     invoiceCode: '', //发票代码
          //     invoiceNo: '', //发票号码
          //     invoiceAmt: '' //发票金额（元）
          // }
        ],
        payableAmt: '', // 应收账款余额(单位元)
        sellCmpUnicode: '', // 统一社会信用代码（销售方)
        sellCmpname: '' // 销售方名称（销售方企业名称）
      }
    }
  },
  computed: {
    FileUrl() {
      return this.$store.getters.getDictionaryItem('FILE_PREFIX_URL')
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      selectCmpApplayInfo(this.$route.query.id, res => {
        this.detailList = res.data
      })
    },
    openFile(id) {
      const url = this.FileUrl[0].dictName + id
      window.open(url)
    },
    goPage(row) {
      this.$router.push({
        path: '/accountsDetails',
        query: {
          id: row.billId
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .edit-box {
    background-color: #fff;
    padding: 14px 18px;

    .title {
      font-weight: 400;
      padding: 0 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 50px;
      height: 50px;
      border-bottom: 1px solid #EEF1F4;

      .tips-txt {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        width: max-content;
      }
    }

    .basic-information {
      padding-top: 20px;

      li {
        float: left;
        width: 40%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 30px;

        .li-label {
          text-align: right;
          min-width: 126px;
          width: 130px;
          font-weight: 600;
        }

        .flex1 {
          max-width: 88%;
          word-wrap: break-word;
        }

        .red-color {
          color: #ff0000;
          font-size: 20px;
        }

        .br-input {
          width: 65%;
        }
      }
    }

    .btn-box {
      text-align: center;
    }
  }
</style>
